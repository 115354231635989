import Error from './old404'

const hasUpperCase = (string) => /[A-Z]/.test(string)
const hasFileExtension = (string) => /\.(.*)$/.test(string)

Error.getInitialProps = async (ctx) => {
  const statusCode = ctx.res ? ctx.res.statusCode : ctx.err ? ctx.err.statusCode : 404
  const parts = ctx.asPath.split('?')
  const asPath = parts[0]

  const queryString = parts[1] ? `?${ parts[1] }` : ''

  const shouldRedirectLowerCase = asPath &&
  hasUpperCase(asPath) &&
  asPath !== asPath.toLowerCase() &&
  !asPath.includes('/api/') &&
 // Only rewrite files that don't have a file extension
 !hasFileExtension(asPath)

  if (shouldRedirectLowerCase) {
    // 307 temp 308 perm
    ctx.res.setHeader('Location', asPath.toLowerCase() + queryString)
    ctx.res.statusCode = 307
  }

  return { statusCode }
}

export default Error
