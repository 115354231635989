import styled from 'styled-components'
import Link from 'next/link'

import Display from './Display'
import Text from './Text'

const NotFound = () => {
  return (
    <Section>
      <Container>
        <PageHeader>
          <Display>We can&apos;t find that page.</Display>
          <Text>The link you clicked may be broken or the page may have been removed.</Text>
        </PageHeader>

        <ErrorMsg>
          <Link href="/" passHref>
            <a>Visit the homepage</a>
          </Link>
          {' '}or{' '}
          <Link href="/contact" passHref>
            <a>contact us</a>
          </Link>{' '}
          about the problem.
        </ErrorMsg>
      </Container>
    </Section>
  )
}

const Section = styled.section`
  position: relative;
  padding: 7rem 0 3rem;
  background-repeat: no-repeat;
  background-position: center top;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    min-height: calc(100vh - 640px);
    padding: 12rem 0 9rem;
    background-position: center center;
  }
`

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
`

const PageHeader = styled.div`
  padding: 0;
  border-bottom: none;
`

const ErrorMsg = styled.p`
  padding-top: 2rem;
  font-size: 14px;
  font-weight: bold;
`

export default NotFound
